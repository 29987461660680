<template>
  <div>
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <br />
    <div class="search">
      <div class="row" id="filters">
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Nº Encom.</span>
            </div>
            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="id_select" @change="filterbyID()" />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Estado</span>
            </div>
            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="state_select" @change="filterbyState()" />
          </div>
        </div>
        <div class="col">
          <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="inputGroup-sizing-sm">Tracking</span>
            </div>
            <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="tracking_select" @change="filterbyTracking()" />
          </div>
        </div>
      </div>
    </div>
    <b-table striped hover :items="ordersFiltered" :fields="fields" :per-page="perPage" :current-page="currentPage" class="table" small thead-class="text-white">
      <template #cell(id)="data">
        <b-link @click="routeToOrder(data.item.id)">
          <span v-if="data.item.priority == false" style="background-color: green; color: white">{{ data.item.id }}</span>
          <span v-else>{{ data.item.id }}</span>
        </b-link>
      </template>
      <template #cell(country)="data">
        {{ data.item.country }}
      </template>
      <template #cell(tracking)="data">
        <b-link @click="openTracking(data.item.url)">{{ data.item.tracking }}</b-link>
      </template>
      <template #cell(company.name)="data">
        {{ data.item.company.name }}
        <br />
        <span v-if="data.item.company.name == 'CTT' || data.item.company.name == 'CTT Expresso'">
          <a v-if="data.item.ticket_id != null">(#{{ data.item.ticket_id }})</a>
          <br />
          <a id="aUpdateAddress" style="text-align: center" v-b-modal.modal-2 @click="passTicket(data.item)">🔃</a>
        </span>
      </template>
      <template #cell(dates.sent)="data">
        {{ data.item.dates.sent }}
      </template>
      <template #cell(company.last_code)="data">
        {{ data.item.company.last_code }}
      </template>
      <template #cell(company.description)="data">
        <span v-if="data.item.company.description != 'Sem dados'">{{ splitDescription(data.item.company.description) }}</span>
        <span v-else>----</span>
      </template>
      <template #cell(company.date_code)="data">
        <span v-if="data.item.company.date_code != 'Sem dados'">{{ splitDate(data.item.company.date_code) }}</span>
        <span v-else>----</span>
      </template>
      <template #cell(dates.ensurance_delivery)="data">
        <span v-if="data.item.dates.ensurance_delivery != undefined">{{ splitDate2(data.item.dates.last_email) }}</span>
        <span v-else>----</span>
      </template>
      <template #cell(dates.last_email)="data">
        <span v-if="data.item.dates.last_email != undefined">{{ splitDate2(data.item.dates.last_email) }}</span>
        <span v-else>----</span>
      </template>
      <template #cell(actions)="data">
        <span id="sendEmail" @click="confirmEmail(data.item)">Enviar E-mail</span>
        <hr />
        <div v-if="data.item.company.name == 'CTT' || data.item.company.name == 'CTT Expresso'">
          <span id="sendEmailS" @click="confirmEmailS(data.item)">Enviar E-mail Seguro</span>
          <hr />
        </div>
        <span id="delivered" @click="confirmDelivered(data.item)">Marcar como entregue</span>
        <hr />
        <!-- <span id="lost" @click="lostOrder(data.item.id)">Encomenda Perdida</span> -->
        <div class="d-flex">
          <b-button id="lostWithInsurance" variant="success" class="mr-2" @click="lostOrder(data.item.id, 'insurance')">Encomenda Perdida (Com Seguro)</b-button>
          <b-button id="lostWithoutInsurance" variant="danger" @click="lostOrder(data.item.id, 'no_insurance')">Encomenda Perdida (Sem Seguro)</b-button>
        </div>
      </template>
    </b-table>

    <b-modal ref="modal_ticket" id="modal-2" title="Atualizar Ticket CTT" hide-footer>
      <form v-on:submit.prevent="updateTicket()">
        <div class="form-group">
          <label for="contactInput">Ticket:</label>
          <div class="input-group mb-3">
            <input type="text" class="form-control" id="ticketInput" v-model="ticket_id" />
          </div>
        </div>
        <div id="btnUpdateAddress">
          <button type="submit" class="btn btn-primary">Atualizar</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loading from '@/components/Loading.vue';

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      orders: [],
      ordersFiltered: [],
      id_select: '',
      tracking_select: '',
      state_select: '',
      page: '',
      rows: '',
      perPage: 10,
      currentPage: 1,
      ticket_id: '',
      order: '',
      fields: [
        {
          key: 'id',
          label: 'Nº Encmd.',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'country',
          label: 'País',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'tracking',
          label: 'Tracking',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle; width:10% ',
          sortable: false,
        },
        {
          key: 'company.name',
          label: 'Distribuidora',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'dates.sent',
          label: 'Data de Envio',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'company.last_code',
          label: 'Último Código',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'company.description',
          label: 'Descrição código',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
        {
          key: 'company.date_code',
          label: 'Data último código',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'dates.ensurance_delivery',
          label: 'Data do email Seguro',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'dates.last_email',
          label: 'Data do último email',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Ações',
          tdClass: 'align-middle',
          thStyle: 'font-size: 18px; background-color:rgb(255, 147, 147);vertical-align: middle',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async lostOrder(id, status) {
      if (status == 'insurance') {
        this.$swal({
          title: 'Encomeda Perdida',
          text: 'Quer colocar a encomenda ' + id + ' como perdida com seguro?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch('lostOrder', {
                id: id,
                status: 'lost_with_insurance',
              });
              this.$swal.fire('Com Seguro!', '', 'success');
              await this.getNotDeliveredOrders();
            } catch (err) {
              alert(err);
            }
          }
        });
      } else if (status == 'no_insurance') {
        this.$swal({
          title: 'Encomeda Perdida',
          text: 'Quer colocar a encomenda ' + id + ' como perdida sem seguro?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Cancelar',
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch('lostOrder', {
                id: id,
                status: 'lost_without_insurance',
              });
              this.$swal.fire('Sem Seguro!', '', 'success');
              await this.getNotDeliveredOrders();
            } catch (err) {
              alert(err);
            }
          }
        });
      }
    },
    async confirmDelivered(order) {
      this.$swal({
        title: 'Quer colocar a encomenda ' + order.id + ' como entregue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.setOrderDelivered(order);
        }
      });
    },
    async confirmEmail(order) {
      this.$swal({
        title: 'Deseja enviar email a perguntar pela encomenda ' + order.id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.sendEmail(order);
        }
      });
    },
    async confirmEmailS(order) {
      this.$swal({
        title: 'Deseja enviar email a ativar o seguro da encomenda ' + order.id + ' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim!',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.sendEmailS(order);
        }
      });
    },
    async getNotDeliveredOrders() {
      this.loading = true;
      try {
        await this.$store.dispatch('getTrackingNotDel');
        this.orders = this.getTrackingNotDel;
        this.ordersFiltered = this.orders;
        this.perPage = this.ordersFiltered.length;
        console.log(this.orders);
      } catch (err) {
        console.log(err);
        alert(err);
      }
      this.loading = false;
    },
    async setOrderDelivered(order) {
      try {
        await this.$store.dispatch('setOrderDelivered', {
          order_id: order.id,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Estado alterado para entregue',
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getNotDeliveredOrders();
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async sendEmail(order) {
      try {
        await this.$store.dispatch('sendEmailTransport', {
          id: order.id,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Email Enviado',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      await this.getNotDeliveredOrders();
    },
    async sendEmailS(order) {
      try {
        await this.$store.dispatch('sendEmailTransportS', {
          id: order.id,
        });
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: 'Email Enviado',
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (err) {
        console.log(err);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: err,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      await this.getNotDeliveredOrders();
    },
    async routeToOrder(id) {
      window.open(`https://${window.location.host}/orders/${id}`, '_blank');
    },
    async openTracking(url) {
      window.open(url, '_blank');
    },
    //Others
    splitDate(date) {
      let split = date.split('T');
      let date2 = `${split[1]}`;
      let split2 = date2.split('.000Z');
      return `${split[0]} ${split2[0]}`;
    },
    splitDate2(date) {
      let split = date.split('T');
      return `${split[0]}`;
    },
    splitDescription(description) {
      let split = description.split('.');
      return `${split[0]}`;
    },
    filterbyID() {
      this.tracking_select = '';
      this.state_select = '';
      if (this.id_select == '') {
        this.ordersFiltered = this.orders;
        this.perPage = this.ordersFiltered.length;
      } else {
        this.ordersFiltered = this.orders.filter((order) => order.id == this.id_select);
        this.perPage = this.ordersFiltered.length;
      }
    },
    filterbyTracking() {
      this.id_select = '';
      this.state_select = '';
      if (this.tracking_select == '') {
        this.ordersFiltered = this.orders;
        this.perPage = this.ordersFiltered.length;
      } else {
        this.ordersFiltered = this.orders.filter((order) => order.tracking == this.tracking_select);
        this.perPage = this.ordersFiltered.length;
      }
    },
    filterbyState() {
      this.id_select = '';
      this.tracking_select = '';
      if (this.state_select == '') {
        this.ordersFiltered = this.orders;
        this.perPage = this.ordersFiltered.length;
      } else {
        this.ordersFiltered = this.orders.filter((order) => order.company.description.toLowerCase().includes(this.state_select.toLowerCase()));
        this.perPage = this.ordersFiltered.length;
      }
    },
    async passTicket(order) {
      this.ticket_id = order.ticket_id;
      this.order = order.id;
    },
    async updateTicket() {
      try {
        this.$store.dispatch('updateTicketTracking', {
          order_id: this.order,
          ticket_id: this.ticket_id,
        });
        this.$swal('Completado', 'Ticket atualizado!', 'success');
        await this.getNotDeliveredOrders();
        this.ticket_id = '';
        this.order = '';
        this.$refs['modal_ticket'].hide();
      } catch (error) {
        console.log(error);
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'Erro ao atualizar ticket',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
  computed: {
    ...mapGetters(['getTrackingNotDel']),
  },
  async created() {
    await this.getNotDeliveredOrders();
  },
};
</script>

<style>
/* PESQUISA */
#filters {
  padding: 0px 100px 0px 100px;
  text-align: left;
}
#sendEmail {
  color: rgb(0, 11, 168);
}
#sendEmail:hover {
  text-decoration: underline;
  cursor: pointer;
}
#sendEmailS {
  color: rgb(207, 38, 8);
}
#sendEmailS:hover {
  text-decoration: underline;
  cursor: pointer;
}

#delivered {
  color: rgb(19, 116, 0);
}
#delivered:hover {
  text-decoration: underline;
  cursor: pointer;
}
#lost {
  color: orange;
}
#lost:hover {
  text-decoration: underline;
  cursor: pointer;
}
#aUpdateAddress {
  margin-left: 5px;
  cursor: pointer;
}
#aUpdateAddress:hover {
  cursor: pointer;
}
</style>
